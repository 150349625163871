<template>
  <nav>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-img
        class="pa-2"
        :lazy-src="'/img/logo3.gif'"
        max-height="50"
        max-width="50"
        :src="'/img/logo3.gif'"
      /> 
      <span class="text-h4 success--text pa-2"> Portal</span>
      <v-menu
        :close-on-content-click="false"
        :nudge-width="200"
      >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="pa-2"
          color="blue"
          dark
          v-bind="attrs"
          v-on="on"
          small
          fab
        >
        <v-icon dark>
          fab fa-telegram
        </v-icon>
        </v-btn>
      </template>
        <v-card>
          <v-card-title>
            <span class="text-h6">Cập nhật Telegram</span>
          </v-card-title>
          <v-card-text>
            Chat <code>/verify {{getUser._id}}</code>
          </v-card-text>
        </v-card>
      </v-menu>
      <v-spacer></v-spacer>
      <template v-if="isAuth">
        <v-btn text @click="onLogout">
            <span>Thoát</span>
        </v-btn>
      </template>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer" 
      dark app class="primary" v-if="isAuth"
      expand-on-hover
    >
      <!-- <div class="text-h6 secondary--text text-center">Hi, {{ getUser.name }}</div> -->
      <v-list>
        <v-list-item class="px-2" v-if="getUser.profileImage">
          <v-list-item-avatar>
            <v-img
              v-if="getUser.profileImage"
              :lazy-src="getUser.profileImage"
              max-height="150"
              max-width="250"
              aspect-ratio="16:9"
              contain
              :src="getUser.profileImage"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-h6 secondary--text">
              Hi, {{ getUser.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item  router to="/rut-tien" active-class="border">
          <v-list-item-action>
              <v-icon>mdi-currency-usd</v-icon>
          </v-list-item-action>
          <v-list-item-content >
              <v-list-item-title><strong>Số dư:</strong> {{DoiTien(getUser.Balance)}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template v-for="(link,index) in links">
          <v-list-item router :to="Link(link.url)" active-class="border" :key="index" v-if="!link.admin">
              <v-list-item-action>
                  <v-icon >{{link.icon}}</v-icon>
              </v-list-item-action>
              <v-list-item-content >
                  <v-list-item-title>{{link.title}}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
          <v-list-item router :to="Link(link.url,true)" active-class="border" :key="index" v-if="link.admin && getUser.Access === 'admin'">
              <v-list-item-action>
                  <v-icon >{{link.icon}}</v-icon>
              </v-list-item-action>
              <v-list-item-content >
                  <v-list-item-title><v-icon>mdi-crown</v-icon> {{link.title}}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import {mapGetters} from 'vuex'
import {DoiTien, MyAccount} from '@/helpers'
import links from '@/config/uri.js';
export default {
  data () {
    return {
      links,
      drawer: true,
      autoLoad: undefined,
    }
  },
  computed:{
    ...mapGetters(['isAuth','getUser']),
  },
  methods:{
    DoiTien,
    Link(link){
      return `/${link}`
    },
    UserData(){
      if(this.isAuth){
        MyAccount().then(json=>{
          if(json.data){
            this.$store.commit('setUserData',json.data);
          }
        })
      }
    },
    onLogout(){
      this.$store.commit('doLogout');
      this.$router.push('/login');
    }
  },
  mounted(){
    this.UserData()
    this.autoLoad = setInterval(this.UserData,10000)
  },
  beforeDestroy(){
    clearInterval(this.autoLoad)
    this.autoLoad = undefined
  }
}
</script>

<style>

</style>