import Vue from 'vue'
import Vuex from 'vuex'
import { setStore, getStore, removeItem } from '@/helpers'
import router from '@/router'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    User: getStore('user-data'),
    Token: getStore('Bo-Auth-Token'),
    UserData: {},
  },
  mutations: {
    setLogin(state, user) {
      state.User = user
      setStore('user-data', user)
    },
    doLogin(state, login){
      state.Token = login
      setStore('Bo-Auth-Token', login)
    },
    setUserData(state, data) {
      state.UserData = data
    },
    doLogout(state) {
      state.UserData = {}
      state.User = undefined
      state.Token = undefined
      removeItem('user-data')
      removeItem('Bo-Auth-Token')
      router.push('/login')
    },
  },
  getters: {
    isAuth(state) {
      return !!state.Token
    },
    Token(state){
      return state.Token
    },
    getUser(state) {
      return {
        ...state.User,
        ...state.UserData,
      }
    },
  }
})
