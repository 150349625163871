<template>
  <v-app>
    
    <navbar />
    <v-main>
      <router-view/>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import navbar from '@/components/navbar.vue'
import Footer from '@/components/footer.vue'
export default {
  name: 'App',
  components:{
    navbar,
    Footer
  },
  data: () => ({
    //
  }),
  computed:{

  },
  methods:{

  }
};
</script>
