import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'  //Globally import VTextField
import GoogleAuth from './plugins/google'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
const gauthOption = {
  clientId: '116690301390-m56tlbouesbp9pkge03rh8mvdbfahfus.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account'
}
Vue.use(GoogleAuth, gauthOption)
Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, { 
	locale: 'vi-VN',
	decimalLength: 0,
	autoDecimalMode: true,
	min: null,
	max: null,
	defaultValue: 0,
   valueAsInteger: true,
  allowNegative: false
})
Vue.config.productionTip = false
Vue.prototype.$Version = "v1.220216";
const vm = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
export default vm