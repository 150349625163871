export const autoCardNum = (data) =>{
  const cc = data.split('|');
  let num = undefined;
  const reg = new RegExp('^[0-9]+$');
  cc.forEach(item=>{
    const x = item.trim();
    const first = parseInt(x.substring(0,1));
    if(reg.test(x) !== true) return;
    if(x.length === 15 && first === 3) num = x;
    if(x.length === 16 && (first === 4 || first === 5 || first === 6)) num = x;
  })
  return num;
}