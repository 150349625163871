import { ResponseData, ResponseError } from './response'
import { axiosInstance, Request } from './request'
export const GoogleAuth = (access_token) =>{
  return axiosInstance.post(`/auth`,{access_token}).then(ResponseData)
}
export const MyAccount = () =>{
  const req = Request();
  return axiosInstance.get(`/auth`,req).then(ResponseData).catch(err=>{
    console.log('Auth Error: ',err);
  });
}