import Swal from 'sweetalert2';
const toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 6000,
  timerProgressBar: true,
})
export const Toast = {
  Error: (msg) => {
    return toast.fire({
      icon: 'error',
      title: msg
    });
  },
  Success: (msg) => {
    return toast.fire({
      icon: 'success',
      title: msg
    });
  },
}
export const ThongBao = {
  Success: (msg) =>{
    return Swal.fire({
      text: msg,
      icon: 'success'})
  },
  Error: (msg) =>{
    return Swal.fire({
      text: msg,
      icon: 'error'})
  },
  Warning: (msg) =>{
    return Swal.fire({
      text: msg,
      icon: 'warning'})
  },
  XacNhan: (msg)=>{
    return Swal.fire({
      title: "Bạn có chắc chắn ?",
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Có',
      cancelButtonText: 'Không'
    }).then((result) => {
        return result.isConfirmed;
    })
  }
}