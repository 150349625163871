export const AccountStatus = {
  admin:{
    t:'Admin',
    c:'red'
  },
  member:{
    t:'Member',
    c:'green'
  },
  inactive:{
    t:'Chưa kích hoạt',
    c:'orange'
  },
}
export const TransactionStatus = {
  nap:{
    t:'Nạp',
    c:'green'
  },
  rut:{
    t:'Rút',
    c:'red'
  },
  cho_duyet:{
    t:'Chờ duyệt',
    c:'orange',
  },
  huy:{
    t:'Đã hoàn tiền',
    c:'pink',
  }
}
export const TeespringStatus = {
  cho_xu_ly:{
    t:'Chờ xử lý',
    c:'blue darken-2'
  },
  dang_xu_ly:{
    t:'Đang xử lý',
    c:'blue'
  },
  da_xu_ly:{
    t:'OK',
    c:'green'
  },
  cho_thanh_toan:{
    t:'Chờ thanh toán',
    c:'orange',
  },
  die:{
    t:'Acc Die',
    c:'red',
  }
}
export const CheckHangStatus = {
  san_sang: {
    t:'Sẵn sàng',
    c:'blue',
  },
  loi: {
    t:'Lỗi',
    c:'red',
  },
  huy: {
    t:'Đã hủy',
    c:'red',
  },
  dang_huy: {
    t:'Đang hủy...',
    c:'orange',
    l:true,
  },
  het_acc: {
    t:'Hết Account',
    c:'red',
  },
  dang_xu_ly: {
    t:'Đang xử lí...',
    c:'blue',
    l:true,
  },
  dang_check: {
    t:'Đang check...',
    c:'green',
    l:true,
  },
  hoan_tat: {
    t:'Hoàn tất',
    c:'orange',
  },
}