export default [
  {url:'','icon':'mdi-forum',title:'Bài viết'},
  {url:'lich-su','icon':'mdi-history',title:'Lịch sử'},
  {url:'chrome','icon':'mdi-google-chrome',title:'Bo Chrome'},
  {url:'teespring','icon':'mdi-hanger',title:'Teespring'},
  {url:'steam','icon':'fab fa-steam',title:'Steam'},
  {url:'check-hang','icon':'mdi-credit-card-check',title:'Check Hàng'},
  {url:'totp','icon':'mdi-two-factor-authentication',title:'TOTP 2FA'},
  {url:'mail','icon':'mdi-email',title:'eMail'},
  {url:'bin','icon':'mdi-credit-card-check',title:'BinBase'},
  {url:'kho-the','icon':'mdi-credit-card',title:'Kho Thẻ'},
  {url:'admin/teespring','icon':'mdi-hanger',title:'Teespring', admin:true},
  {url:'admin/tai-khoan','icon':'mdi-account-supervisor-circle',title:'Tài khoản', admin:true},
  {url:'admin/viewer','icon':'mdi-book-open',title:'UUID Checker', admin:true},
  {url:'admin/system','icon':'mdi-cog',title:'System', admin:true},
];