import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import GoogleIcon from '@/components/icons/google.vue'

Vue.use(Vuetify);

export default new Vuetify({
  theme:{
    dark:true,
    themes:{
      dark:{
        primary:'#474747',
        secondary:'#1fc44b',
      }
    }
  },
  icons: {
    values: {
      google: {
        component: GoogleIcon,
      },
    },
  },
});
