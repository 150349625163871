import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import URI from '@/config/uri.js';
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/thread/:id',
    name: 'Thread',
    component: () => import('../views/Thread.vue')
  },
  {
    path: '/lich-su',
    name: 'lichsu',
    component: () => import('../views/lichsu.vue')
  },
  {
    path: '/rut-tien',
    name: 'ruttien',
    component: () => import('../views/rut.vue')
  },
  {
    path: '/admin/tai-khoan',
    name: 'taikhoan',
    component: () => import('../views/admin/taikhoan.vue')
  },
  {
    path: '/teespring',
    name: 'teespring',
    component: () => import('../views/teespring.vue')
  },
  {
    path: '/totp',
    name: 'totp',
    component: () => import('../views/totp.vue')
  },
  {
    path: '/mail',
    name: 'mail',
    component: () => import('../views/mail.vue')
  },
  {
    path: '/bin',
    name: 'bin',
    component: () => import('../views/Bin.vue')
  },
  {
    path: '/kho-the',
    name: 'KhoThe',
    component: () => import('../views/KhoThe.vue')
  },
  {
    path: '/admin/teespring',
    name: 'admin-teespring',
    component: () => import('../views/admin/teespring.vue')
  },
  {
    path: '/admin/viewer',
    name: 'admin-viewer',
    component: () => import('../views/admin/uuidview.vue')
  },
  {
    path: '/admin/system',
    name: 'admin-system',
    component: () => import('../views/admin/system.vue')
  },
  {
    path: '/check-hang',
    name: 'check-hang',
    component: () => import('../views/checkhang.vue')
  },
  {
    path: '/chrome',
    name: 'chrome',
    component: () => import('../views/chrome.vue')
  },
  {
    path: '/steam',
    name: 'Steam',
    component: () => import('../views/Steam.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/access_denied',
    name: 'access_denied',
    component: () => import('../views/access.vue')
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'active',
  routes
})
router.beforeEach((to, from, next) => {
  const publicPages = ['/login','/access_denied']
  let adminPages = [];
  URI.map(item=>{
    if(item.admin === true) adminPages.push(`/${item.url}`);
  });
  const authRequired = !publicPages.includes(to.path)
  const adminRequired = adminPages.includes(to.path)
  const loggedIn = store.getters.isAuth
  const UserData = store.getters.getUser
  console.log(UserData);
  if (authRequired && !loggedIn) {
    return next('/login')
  }
  if(adminRequired && UserData.email !== 'toanhdcnet@gmail.com'){
    return next('/access_denied')
  }
  next()
})
export default router
