import moment from 'moment-timezone'
export const ThoiGian = (tg) => {
  return moment(tg).tz('Asia/Ho_Chi_Minh').format('DD/MM/YYYY HH:mm');
}
export const TimeAgo = (tg,prefix=false) => {
  return moment(tg).tz('Asia/Ho_Chi_Minh').fromNow(prefix);
}
export const GetLast = (data,last=4) =>{
  return data.splice(data.length-last);
}
export const GetStr = (string,from,to) =>{
  const result = string.match(new RegExp(from + "(.*)" + to));
  // console.log('GetStr: ',result);
  return result;
}
export const hasNumber = (myString) => {
  return /\d/.test(myString);
}