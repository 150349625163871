import { ThongBao, Toast } from './thongbao';
import store from '@/store'
import router from '@/router';
export const ResponseError = (e) =>{
  if(e){
    console.log('Lỗi Request: ',e)
    if(e.response){
      if(e.response.data.error){
        ThongBao.Error(e.response.data.error)
      }
      if(e.response.data.access_denied === true){
        router.push('/access_denied')
      }
      if(e.response.data.auth === false){
        store.commit('doLogout')
      }
    }else{
      if(e.error !== "popup_closed_by_user"){
        const msg = e.error || "Không thể đăng nhập. Hãy thử lại !!!"
        ThongBao.Error(msg)
      }
    }
    return e;
  }
}
export const ResponseData = (resp) =>{
  if(resp.data){
    if(resp.data.auto) return resp.data;
    if(resp.data.error){
      if(!resp.data.toast) ThongBao.Error(resp.data.error)
      else Toast.Error(resp.data.error)
    }else if(resp.data.success){
      if(!resp.data.toast) ThongBao.Success(resp.data.success)
      else Toast.Success(resp.data.success)
    }
    return resp.data;
  }
}