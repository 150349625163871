import API from '@/config/api';
import store from '@/store'
import axios from 'axios'
export const axiosInstance = axios.create({
  baseURL: API.url,
  timeout: 1000 * 60,
});
export const Request = () => {
  return {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': store.getters.Token
    }
  }
}
